<template>
    <div>
        <Details
            model="IDEL"
            :floors="2"
            :parking="2"
            :rooms="2"
            baths="1 ½"
            :area="140"
            :construction="64.17"
            :lowerView="lowerViewIdel"
            :topView="topViewIdel"
            :hasSecondFloor="true"
        />
    </div>
</template>

<script>
import Details from "../components/Details.vue";

import lowerViewIdel from '@/assets/img/IDEL/top.jpg'
import topViewIdel from '@/assets/img/IDEL/top_segunda_planta.jpg'

export default {
    name: "Idel",
    components: { Details },
    mounted() {
        window.scrollTo(0, 0)
    },

    data() {
        return {
            lowerViewIdel,
            topViewIdel
        }
    }
}
</script>