<template>
    <div>
        <header
        class="
          page-header-ui-dark
          bg-img-cover
          overlay overlay-primary overlay-90
        "
      >
        <div class="page-header-ui-content position-relative">
            <div class="row gx-5 justify-content-center">
                <img
                  class="logo_home"
                  :src=cirios
                  alt="BELLA CALIFORNIA"
                />
            </div>
        </div>
        <Curve color="#fff"/>
      </header>
      <section class="bg-white py-10 logo-container">
        <div class="container px-5">
          <div class="row gx-5 align-items-center justify-content-center">
            <div class="col-md-9 col-lg-6" data-aos="slide-left">
              <div class="mb-4">
                <div class="content-skewed content-skewed-left">
                  <img
                    class="img-fluid"
                    :src="cirios"
                    alt="BELLA CALIFORNIA"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mb-5">
                <h2>Los Cirios</h2>
                <p class="lead">
                    Conjunto de 10 residencias a un costado de UABCS, cerca de
                    plazas comerciales y servicio médico.
                    Viviendas de 2 plantas, 3 recamaras. Calidad confort, distribucion y
                    garantias con ecotecnologias en accesorios.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br /><br /><br />
  
        <div class="container px-5">
          <div class="row g-0 align-items-center">
            <div class="col-lg-6">
              <div class="card pricing pricing-left bg-gray-800">
                <div class="card-body p-5 text-white">
                  <p>Los Cirios residencial</p>
                  <p>La Paz, Baja California Sur, México</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-5 mb-lg-0">
              <div class="card pricing">
                <div class="card-body p-5">
                  <Map
                    location="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Los%20Cirios%20Residencial%2C%20Solidaridad%20Mezquitito%20II%2C%20La%20Paz%2C%20B.C.S.+(Title)&amp;ie=UTF8&amp;t=&amp;z=17&amp;iwloc=B&amp;output=embed" />
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <Curve color="#f2f6fc" />
      </section>
  
      <section class="bg-light py-10">
        <div class="houseModels">
          <div class="container px-5">
            <div class="d-flex align-items-center justify-content-center mb-4">
              <h2 class="mb-0">Conoce el modelo de casa</h2>
            </div>
            <div class="row gx-5 justify-content-center">
              <HouseCard
                title="CIRIOS"
                :floors="2"
                :parking="1"
                :rooms="3"
                baths="2 ½"
                :area="142"
                :construction="123.16"
                :img="cirios_model"
                v-on:click="goTo('Cirios')"
              />
              
            </div>
          </div>
        </div>
        <Curve color="#fff" />
      </section>
  
      <section class="bg-white py-10">
        <div class="container text-center">
            <div class="d-flex flex-row justify-content-center align-items-center">
                <div>
                    <div class="container-videos">
                        <video
                        class="video-inm-del-sol"
                        id="video-1"
                        src="assets/video/loscirios.mp4"
                        controls
                        ></video>
                    </div>
                </div>
            </div>
        </div>
        <Curve color="#f2f6fc" />
      </section>
  
      <section class="bg-ligth py-10">
        <div class="text-center">
          <h2>Proyectos anteriores</h2>
          <br />
        </div>
        <div class="container px-5">
          <div class="row gx-5">
            <div class="col-md-6 mb-5">
              <a class="card card-portfolio h-100 logos">
                <img class="card-img-top" :src="alamos" alt="..." />
              </a>
            </div>
            <div class="col-md-6 mb-5">
              <a class="card card-portfolio h-100 logos">
                <img class="card-img-top" :src="terranova" alt="..." />
              </a>
            </div>
            <div class="col-md-6 mb-5">
              <a class="card card-portfolio h-100 logos">
                <img class="card-img-top" :src="sierra" alt="..." />
              </a>
            </div>
            <div class="col-md-6 mb-5">
              <a class="card card-portfolio h-100 logos">
                <img class="card-img-top" :src="mediterraneo" alt="..." />
              </a>
            </div>
          </div>
        </div>
        <Curve color="#212832" />
      </section>
    </div>
  </template>
  
  <script>
  import HouseCard from "../components/HouseCard.vue";
  import Curve from "../components/Curve.vue";
  import router from "../router/index";
  import Map from "../components/Map.vue";
  
  import logoBellaCalifornia from "@/assets/img/LOGO BELLA CALIFORNIA.jpg";
  import ambia from "@/assets/img/AMBIA/perspective.jpg";
  import kalal from "@/assets/img/KALAL/perspective1.jpg";
  import idel from "@/assets/img/IDEL/perspective.jpg";
  import etapa1 from "@/assets/img/Etapas/Etapa1.jpg";
  import etapa2 from "@/assets/img/Etapas/Etapa2.jpg";
  import etapa3 from "@/assets/img/Etapas/Etapa3.jpg";
  import etapa4 from "@/assets/img/Etapas/Etapa4.jpg";
  import etapa5 from "@/assets/img/Etapas/Etapa5.jpg";
  import alamos from "@/assets/img/logo alamos.jpg";
  import terranova from "@/assets/img/terranova.jpg";
  import sierra from "@/assets/img/SierraLago.png";
  import mediterraneo from "@/assets/img/logo mediterraneo.jpg";
  import cirios from "@/assets/img/Los Cirios.png";
  import cirios_model from "@/assets/img/Los Cirios/cirios fachada.jpg";
  
  export default {
    name: "Portafolio",
    components: {
      HouseCard,
      Curve,
      Map,
    },
  
    mounted() {
      window.scrollTo(0, 0);
    },
  
    data() {
      return {
        logoBellaCalifornia,
        ambia,
        kalal,
        idel,
        etapa1,
        etapa2,
        etapa3,
        etapa4,
        etapa5,
        alamos,
        terranova,
        sierra,
        mediterraneo,
        cirios,
        cirios_model
      };
    },
  
    methods: {
      goTo: function (model) {
        router.push({ name: model });
      },
    },
  };
  </script>
  
  <style scoped>

  header {
    background-color: rgb(0, 189, 0);
  }
  .logo_home {
    width: 50vw;
    height: auto;
    padding: 10vmin;
    animation-duration: 3s;
    animation-name: backgroundin;
  }
  
  .timeline {
    position: relative;
    padding: 0;
    list-style: none;
  }
  
  .timeline:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    content: "";
    background-color: #e9ecef;
  }
  .timeline > li {
    position: relative;
    min-height: 50px;
    margin-bottom: 50px;
  }
  .timeline > li:after,
  .timeline > li:before {
    display: table;
    content: " ";
  }
  .timeline > li:after {
    clear: both;
  }
  .timeline > li .timeline-panel {
    position: relative;
    float: right;
    width: 100%;
    padding: 0 20px 0 100px;
    text-align: left;
  }
  .timeline > li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
  }
  .timeline > li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
  }
  .timeline > li .timeline-image {
    position: absolute;
    z-index: 100;
    left: 0;
    width: 80px;
    height: 80px;
    margin-left: 0;
    text-align: center;
    color: white;
    border: 7px solid #e9ecef;
    border-radius: 100%;
    background-color: #a8dadc;
  }
  .timeline > li .timeline-image h4,
  .timeline > li .timeline-image .h4 {
    font-size: 10px;
    line-height: 14px;
    margin-top: 12px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left;
  }
  .timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
  }
  .timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
  }
  .timeline > li:last-child {
    margin-bottom: 0;
  }
  .timeline .timeline-heading h4,
  .timeline .timeline-heading .h4 {
    margin-top: 0;
    color: inherit;
  }
  .timeline .timeline-heading h4.subheading,
  .timeline .timeline-heading .subheading.h4 {
    text-transform: none;
  }
  .timeline .timeline-body > ul,
  .timeline .timeline-body > p {
    margin-bottom: 0;
  }
  
  .fin_timeline {
    color: white;
  }
  
  .logo-container {
    text-align: center;
    padding-top: 50px;
  }
  
  .logos {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  
  @media (min-width: 768px) {
    .timeline:before {
      left: 50%;
    }
    .timeline > li {
      min-height: 100px;
      margin-bottom: 100px;
    }
    .timeline > li .timeline-panel {
      float: left;
      width: 41%;
      padding: 0 20px 20px 30px;
      text-align: right;
    }
    .timeline > li .timeline-image {
      left: 50%;
      width: 100px;
      height: 100px;
      margin-left: -50px;
    }
    .timeline > li .timeline-image h4,
    .timeline > li .timeline-image .h4 {
      font-size: 13px;
      line-height: 18px;
      margin-top: 16px;
    }
    .timeline > li.timeline-inverted > .timeline-panel {
      float: right;
      padding: 0 30px 20px 20px;
      text-align: left;
    }
  }
  
  @media (min-width: 992px) {
    .timeline > li {
      min-height: 150px;
    }
    .timeline > li .timeline-panel {
      padding: 0 20px 20px;
    }
    .timeline > li .timeline-image {
      width: 150px;
      height: 150px;
      margin-left: -75px;
    }
    .timeline > li .timeline-image h4,
    .timeline > li .timeline-image .h4 {
      font-size: 18px;
      line-height: 26px;
      margin-top: 30px;
    }
    .timeline > li.timeline-inverted > .timeline-panel {
      padding: 0 20px 20px;
    }
  }
  
  @media (min-width: 1200px) {
    .timeline > li {
      min-height: 170px;
    }
    .timeline > li .timeline-panel {
      padding: 0 20px 20px 100px;
    }
    .timeline > li .timeline-image {
      width: 170px;
      height: 170px;
      margin-left: -85px;
    }
    .timeline > li .timeline-image h4,
    .timeline > li .timeline-image .h4 {
      margin-top: 40px;
    }
    .timeline > li.timeline-inverted > .timeline-panel {
      padding: 0 100px 20px 20px;
    }
  }
  </style>
  