<template>
    <div class="myMap">
        <iframe
            width="100%"
            height="300"
            :src="location"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
        ></iframe>
        <div
            style="position: absolute;width: 80%;bottom: 10px;left: 0;right: 0;margin-left: auto;margin-right: auto;color: #000;text-align: center;"
        >
            <small style="line-height: 1.8;font-size: 2px;background: #fff;">
                Powered by
                <a href="http://www.googlemapsgenerator.com/ja/">gmapgen jp</a> &
                <a href="http://www.mysmiley.net">smiley emoticons</a>
            </small>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Map',

    props: {
        location: String,
    }
};
</script>

<style>
#gmap_canvas img {
    max-width: none !important;
    background: none !important;
}

.myMap {
    width: 100%;
    position: relative;
}
</style>