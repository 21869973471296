import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Portafolio from '../views/Portafolio.vue'
import Financing from '../views/Financing.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import ThankYou from '../views/ThankYou.vue'
import NotFound from '../views/NotFound.vue'
import Ambia from '../views/Ambia.vue'
import Kalal from '../views/Kalal.vue'
import Idel from '../views/Idel.vue'
import BellaCalifornia from '../views/BellaCalifornia.vue'
import LosCirios from '../views/LosCirios.vue'
import Cirios from '../views/Cirios.vue'

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/portafolio',
        name: 'Portafolio',
        component: Portafolio
    },
    {
        path: '/financing',
        name: 'Financing',
        component: Financing
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/thankyou',
        name: 'ThankYou',
        component: ThankYou
    },
    {
        path: '/ambia',
        name: 'Ambia',
        component: Ambia
    },
    {
        path: '/kalal',
        name: 'Kalal',
        component: Kalal
    },
    {
        path: '/idel',
        name: 'Idel',
        component: Idel
    },
    {
        path: '/bella-california',
        name: 'BellaCalifornia',
        component: BellaCalifornia
    },
    {
        path: '/los-cirios',
        name: 'LosCirios',
        component: LosCirios
    },
    {
        path: '/cirios',
        name: 'Cirios',
        component: Cirios
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router