<template>
    <!-- Navbar-->
    <nav class="navbar navbar-marketing navbar-expand-lg bg-blue-del-sol navbar-light color-white">
        <div class="container px-5">
            <img 
            :src="logo" 
            alt="Logo Inmobiliaria del Sol"
            class="logo"
            v-on:click="goHome()">
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto me-lg-5">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">Inicio</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/portafolio">Portafolio</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/financing">Financiamiento</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/about">Nosotros</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/contact">Contacto</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import logo from '@/assets/img/LOGO HORIZONTAL.png'
import router from "../router/index";
export default {
    name: 'Nav',

    data() {
        return {
            logo
        }
    },
    methods: {
    goHome: function () {
      router.push({ name: 'Home' });
    },
  },
}
</script>

<style>

    .logo {
        width: 25%;
        height: auto;
        cursor: pointer;
    }

    /** Small screen */
    @media (min-width: 351px) and (max-width: 460px) {
        .logo {
            width: 70%;
        }
    }

    /** Medium screen */
    @media(min-width: 461px) and (max-width: 1024px) {
        .logo {
            width: 50%;
        }
    }

</style>