<template>
    <div>
        <Details
            model="CIRIOS"
            :floors="2"
            :parking="1"
            :rooms="3"
            baths="2.5"
            :area="142"
            :construction="123.16"
            :lowerView="lowerViewCirios"
            :topView="topViewCirios"
            :hasSecondFloor="true"
        />
    </div>
</template>

<script>
import Details from "../components/Details.vue";

import lowerViewCirios from '@/assets/img/Los Cirios/Cirios top2.jpg'
import topViewCirios from '@/assets/img/Los Cirios/Cirios top1.jpg'

export default {
    name: "Cirios",
    components: { Details },
    mounted() {
        window.scrollTo(0, 0)
    },

    data() {
        return {
            lowerViewCirios,
            topViewCirios
        }
    }
}
</script>