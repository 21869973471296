<template>
    <div>
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="4"
                    aria-label="Slide 5"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="5"
                    aria-label="Slide 6"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="6"
                    aria-label="Slide 7"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="7"
                    aria-label="Slide 8"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="8"
                    aria-label="Slide 9"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="9"
                    aria-label="Slide 10"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="10"
                    aria-label="Slide 11"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="11"
                    aria-label="Slide 12"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="12"
                    aria-label="Slide 13"
                ></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div v-if="showOriginal">
                        <img src="../assets/img/KALAL/Carousel/kalal0.png" class="d-block w-100" alt="" />
                    </div>
                    <div v-else>
                        <img src="../assets/img/KALAL/Carousel/kalal0_1.png" class="d-block w-100" alt="" />
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal1.png" class="d-block w-100" alt="" />
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal2.png" class="d-block w-100" alt="" />
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal3.png" class="d-block w-100" alt="" />
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal4.png" class="d-block w-100" alt="" />
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal5.png" class="d-block w-100" alt="" />
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal6.png" class="d-block w-100" alt="" />
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal7.png" class="d-block w-100" alt="" />
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal8.png" class="d-block w-100" alt="" />
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal9.png" class="d-block w-100" alt="" />
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal10.png" class="d-block w-100" alt="" />
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal11.png" class="d-block w-100" alt="" />
                </div>
                <div class="carousel-item">
                    <img src="../assets/img/KALAL/Carousel/kalal12.png" class="d-block w-100" alt="" />
                </div>
            </div>
            <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
            >
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
            >
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
        <h3 class="mt-4">Fachada disponible en dos colores</h3>
        <button class="color kalalVerde" v-on:click="changeColor"></button>
        <button class="color kalalPurpura" v-on:click="changeColor"></button>
    </div>
</template>

<script>
export default {
    name: 'CarouselKalal',
    data() {
        return {
            showOriginal: true
        }
    },
    methods: {
        changeColor: function() {
            if(this.showOriginal) {
                this.showOriginal = false
            } else {
                this.showOriginal = true
            }
        }
    }
}
</script>

<style>
.color {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid black;
}

.kalalVerde {
    background-color: #479691;
}

.kalalPurpura {
    margin-left: 10px;
    background-color: #712645;
}
</style>