<template>
  <div>
    <Header
      title="Nosotros"
      description="Conoce nuestra Misión, Visión y Valores"
      img=""
    />
    <section class="bg-white py-10">
      <div class="container px-5">
        <div class="row gx-5 justify-content-center">
          <div class="col-lg-10">
            <h2 class="mb-4">Acerca de la empresa</h2>
            <p>
              Proporcionar a nuestros clientes, viviendas de la más alta calidad
              e innovación, buscando siempre su beneficio, así como superar sus
              expectativas usando la mejor tecnología y materiales a través de
              nuestro servicio único, responsable y honesto.
            </p>
            <p class="mb-0">“Especialistas construyendo tus espacios”</p>
            <hr class="my-5" />
            <h4 class="mb-4">Misión</h4>
            <p>
              Ser la organización inmobiliaria más reconocida y admirada de
              nuestra localidad, a través de nuestra constante innovación y
              honestidad, basándonos en la especialización y capacitación de
              nuestro personal, respetando las normas para la conservación del
              medio ambiente, permitiéndonos de esta forma ser una empresa
              sólida y competitiva.
            </p>

            <hr class="my-5" />
            <h4 class="mb-4">Visión</h4>
            <p>
              Ser la organización inmobiliaria más reconocida y admirada de
              nuestra localidad, a través de nuestra constante innovación y
              honestidad, basándonos en la especialización y capacitación de
              nuestro personal, respetando las normas para la conservación del
              medio ambiente, permitiéndonos de esta forma ser una empresa
              sólida y competitiva.
            </p>

            <hr class="my-5" />
            <h4 class="mb-4">Valores</h4>
            <ul>
              <li>
                Responsabilidad: Cumplir a tiempo con los compromisos adquiridos
                con nuestros clientes y proveedores.
              </li>
              <li>
                Calidad: Hacer las cosas bien y a la primera para entregar a
                nuestros clientes productos o servicios bajo los mejores
                términos de calidad, eficiencia y costo.
              </li>
              <li>
                Honestidad: Brindar transparencia hacia nuestros clientes en
                todo momento
              </li>
              <li>Nuestros clientes: Nuestra razón de ser.</li>
              <li>
                Nuestros colaboradores: Es la fortaleza de nuestra empresa para
                lograr nuestra misión.
              </li>
            </ul>

            <hr class="my-5" />
            <p>
              "Inmobiliaria Fraccionamiento del Sol, la empresa más joven de La
              Paz con la experiencia de 30 años construyendo para ti…"
            </p>
          </div>
        </div>
      </div>
      <Curve color="#212832"/>
    </section>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Curve from "../components/Curve.vue";

export default {
  name: "About",
  components: {Curve, Header },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>