<template>
    <section class="bg-white py-10">
        <div class="container px-5">
            <div class="row gx-5 justify-content-center">
                <div class="col-lg-6">
                    <div class="text-center mt-4">
                        <p class="lead">¡Vaya! Esta URL no pertenece al sitio web.</p>
                        <router-link to="/">
                            Regresar a la pantalla inicial
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <Curve color="#212832"/>
    </section>
</template>

<script>
import Curve from "../components/Curve.vue";
export default {
    name: "NotFound",
    components: { Curve }
}
</script>