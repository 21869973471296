<template>
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                aria-label="Slide 4"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="4"
                aria-label="Slide 5"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="5"
                aria-label="Slide 6"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="6"
                aria-label="Slide 7"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="7"
                aria-label="Slide 8"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="8"
                aria-label="Slide 9"
            ></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="../assets/img/AMBIA/Carousel/ambia0.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/AMBIA/Carousel/ambia1.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/AMBIA/Carousel/ambia2.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/AMBIA/Carousel/ambia3.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/AMBIA/Carousel/ambia4.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/AMBIA/Carousel/ambia5.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/AMBIA/Carousel/ambia6.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/AMBIA/Carousel/ambia7.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/AMBIA/Carousel/ambia8.png" class="d-block w-100" alt="" />
            </div>
        </div>
        <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
        >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
        >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'CarouselAmbia',
}
</script>