<template>
  <div>
    <h2 class="fw-bolder mb-4 mt-3">¿Qué incluye?</h2>
    <ul>
      <li>Cisternar y tinaco de 1,100L</li>
      <li>Bomba de agua</li>
      <li>Medias bardas</li>
      <li>Cocina integral con placa de granito</li>
      <li>Rejas</li>
      <li>Closet</li>
      <li>Boiler</li>
    </ul>
    <hr />
    <p class="fs-5 mb-4">
      Esta cómoda vivienda consta de 3 recámaras, 2 recámaras situadas en la
      parte superior de la casa, y la 3era recámara situada en la parte baja de
      la vivienda con su propio baño completo con vista al jardín de posterior.
      Las 2 habitaciones son espaciosas y ventiladas. Cuenta con baño completo
      en la en la 2da planta y un 1 ⁄ 2 medio en la planta baja. La distribución
      es agradable con la cocina como un área independiente, mientras que el
      comedor y una confortable sala tiene vista a la entrada de la vivienda.
    </p>
    <p class="fs-5 mb-4">
      El acceso a la casa da directo a la sala y comedor, al no tener separación
      entre ambas áreas da una sensación de amplitud. El piso de loseta
      vitrificada (vitropiso). La cocina es de madera de pino, además cuenta con
      puerta principal de MDF. En la parte posterior de la vivienda cuenta con
      un área de servicios, donde se encuentra el área de lavadero, boiler y
      lavadora.
    </p>
    <p class="fs-5 mb-4">
      La cochera cuenta con un espacio para dos autos. Además de manejar un
      espacio para jardín y una entrada que da la bienvenida a su casa.
    </p>
    <p class="fs-5 mb-4">
      Nuestros proyectos se caracterizan por su sistema constructivo
      tradicional, calidad en la construcción y los acabados. Los muros son de
      block y acabados en yeso pulido, preparación para 3era planta, altura de
      la losa de azotea que permite tener un clima agradable y fresco.
    </p>
  </div>
</template>

<script>
export default {
  name: "InfoAmbia",
};
</script>
