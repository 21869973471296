<template>
    <div>
        <Details
            model="AMBIA"
            :floors="2"
            :parking="2"
            :rooms="3"
            baths="2 ½"
            :area="155"
            :construction="83"
            :lowerView="lowerViewAmbia"
            :topView="topViewAmbia"
            :hasSecondFloor="true"
        />
    </div>
</template>

<script>
import Details from '../components/Details.vue'

import lowerViewAmbia from '@/assets/img/AMBIA/top.jpg'
import topViewAmbia from '@/assets/img/AMBIA/top_segunda_planta.jpg'

export default {
    name: "Ambia",
    components: { Details },
    mounted() {
        window.scrollTo(0, 0)
    },

    data() {
        return {
            lowerViewAmbia,
            topViewAmbia
        }
    }
}
</script>