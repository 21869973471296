<template>
  <div class="myMap">
    <iframe
    width="100%"
    height="300"
    frameborder="0"
    scrolling="no"
    marginheight="0"
    marginwidth="0"
    src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=Fraccionamiento%20Bella%20California,%2023084%20La%20Paz,%20B.C.S+(Fraccionamiento%20Bella%20California)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
    </iframe>

    <div
      style="
        position: absolute;
        width: 80%;
        bottom: 10px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        color: #000;
        text-align: center;
      "
    >
      <small style="line-height: 1.8; font-size: 2px; background: #fff">
        Powered by
        <a href="http://www.googlemapsgenerator.com/ja/">gmapgen jp</a> &
        <a href="http://www.mysmiley.net">smiley emoticons</a>
      </small>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapBellaCal",
};
</script>

<style>
#gmap_canvas img {
  max-width: none !important;
  background: none !important;
}

.myMap {
  width: 100%;
  position: relative;
}
</style>