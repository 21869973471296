<template>
    <div>
        <Details
            model="KALAL"
            :floors="1"
            :parking="2"
            :rooms="2"
            baths="1"
            :area="140"
            :construction="60.89"
            :lowerView="lowerViewKalal"
            :hasSecondFloor="false"
        />
    </div>
</template>

<script>
import Details from "../components/Details.vue";

import lowerViewKalal from '@/assets/img/KALAL/top.jpg'
export default {
    name: "Kalal",
    components: { Details },
    mounted() {
        window.scrollTo(0, 0)
    },

    data() {
        return {
            lowerViewKalal
        }
    }
}
</script>