<template>
    <div>
      <h2 class="fw-bolder mb-4 mt-3">¿Qué incluye?</h2>
      <ul>
        <li>Cisterna de 2,500L</li>
        <li>Tinaco de 1,100L</li>
        <li>Bomba de agua</li>
        <li>Sala y Comedor</li>
        <li>Cocina integral con placa de granito</li>
        <li>3 Closet</li>
      </ul>
      <hr />
    </div>
  </template>
  
  <script>
  export default {
    name: "InfoCirios",
  };
  </script>
  