<template>
    <div>
        <!-- Page content-->
        <div class="container mt-5">
            <div class="row">
                <div class="col-lg-8">
                    <!-- Post content-->
                    <article>
                        <!-- Post header-->
                        <header class="mb-4">
                            <!-- Post title-->
                            <h1 class="fw-bolder mb-1">{{ model }}</h1>
                        </header>
                        <div v-if="model == 'AMBIA'">
                            <CarouselAmbia />
                            <!-- Post content-->
                            <section class="mb-5 pt-4">
                                <InfoAmbia/>
                            </section>
                        </div>
                        <div v-if="model == 'KALAL'">
                            <CarouselKalal />
                            <section class="mb-5 pt-4">
                                <InfoKalal/>
                            </section>
                        </div>
                        <div v-if="model == 'IDEL'">
                            <CarouselIdel />
                            <section class="mb-5 pt-4">
                                <InfoIdel/>
                            </section>
                        </div>
                        <div v-if="model == 'CIRIOS'">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img src="../assets/img/IDEL/Carousel/idel0.png" class="d-block w-100" alt="" />
                                </div>
                            </div>
                            <section class="mb-5 pt-4">
                                <InfoCirios/>
                            </section>
                        </div>
                    </article>
                </div>
                <!-- Side widgets-->
                <div class="col-lg-4">
                    <!-- Information -->
                    <div class="card mb-4">
                        <div class="card-header text-dark">Detalles</div>
                        <div class="card-body">
                            <p>
                                <i class="fa-solid fa-house"></i> 
                                Plantas: {{ floors }}
                            </p>
                            <p>
                                <i class="fa-solid fa-bed"></i> {{ rooms }} |
                                <i class="fa-solid fa-bath"></i> {{ baths }} |
                                <i class="fa-solid fa-car"></i> {{ parking }}
                            </p>
                            <p>
                                Terreno {{ area }} m<sup>2</sup> - Construcción {{ construction }} m<sup>2</sup>
                            </p>
                        </div>
                    </div>
                    <!-- Top view -->
                    <div class="card mb-4">
                        <div class="card-header text-dark">Vista superior</div>
                        <div class="card-body">
                            <div v-if="hasSecondFloor == true">
                                <div v-if="views.viewTop == false">
                                    <div class="d-flex flex-column p-2 align-items-center">
                                        <button class="btn btn-primary" v-on:click="changeView(true)">Ver planta alta</button>
                                    </div>
                                    <img :src="lowerView" alt class="topView" />
                                </div>
                                <div v-if="views.viewTop == true">
                                    <div class="d-flex flex-column p-2 align-items-center">
                                        <button class="btn btn-primary" v-on:click="changeView(false)">Ver planta baja</button>
                                    </div>
                                    <img :src="topView" alt class="topView" />
                                </div>
                            </div>
                            <div v-else>
                                <img :src="lowerView" alt class="topView" />
                            </div>
                        </div>
                    </div>
                    <!-- Information -->
                    <div v-if="model == 'CIRIOS'"></div>
                    <div v-else>
                        <div class="card mb-4">
                            <div class="card-header text-dark">Otros modelos</div>
                            <div class="card-body">
                                <p v-on:click="goTo('AMBIA')" class="btn btn-link">AMBIA</p>
                                <p v-on:click="goTo('KALAL')" class="btn btn-link">KALAL</p>
                                <p v-on:click="goTo('IDEL')" class="btn btn-link">IDEL</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CarouselAmbia from './CarouselAmbia.vue'
import CarouselKalal from './CarouselKalal.vue'
import CarouselIdel from './CarouselIdel.vue'
import InfoAmbia from './InfoAmbia.vue'
import InfoKalal from './InfoKalal.vue'
import InfoIdel from './InfoIdel.vue'
import InfoCirios from './infoCirios.vue'
import router from '../router/index'

export default {
    name: "Details",
    components: {
        CarouselAmbia,
        CarouselKalal,
        CarouselIdel,
        InfoAmbia,
        InfoKalal,
        InfoIdel,
        InfoCirios
    },

    props: {
        floors: Number,
        model: String,
        rooms: Number,
        baths: String,
        parking: Number,
        area: Number,
        construction: String,
        lowerView: String,
        topView: String,
        hasSecondFloor: Boolean
    },

    data() {
        return {
            views: {
                viewTop: false,
            }
        }
    },

    methods: {
        changeView: function(change) {
            if (change) {
                this.views.viewTop = true;
            } else {
                this.views.viewTop = false;
            }
        },

        goTo: function(model){
            if (model == "AMBIA"){
                router.push({name:"Ambia"})
            } else if (model == "KALAL"){
                router.push({name:"Kalal"})
            } else if (model == "IDEL"){
                router.push({name:"Idel"})
            }
        }
    }
}
</script>

<style>
.topView {
    width: 90%;
    height: auto;
    display: flex;
    margin: auto;
}
</style>