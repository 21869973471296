<template>
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
            ></button>
            <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="4"
                    aria-label="Slide 5"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="5"
                    aria-label="Slide 6"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="6"
                    aria-label="Slide 7"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="7"
                    aria-label="Slide 8"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="8"
                    aria-label="Slide 9"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="9"
                    aria-label="Slide 10"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="10"
                    aria-label="Slide 11"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="11"
                    aria-label="Slide 12"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="12"
                    aria-label="Slide 13"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="13"
                    aria-label="Slide 14"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="14"
                    aria-label="Slide 15"
                ></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="../assets/img/IDEL/Carousel/idel0.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel1.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel2.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel3.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel4.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel5.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel6.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel7.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel8.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel9.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel10.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel11.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel12.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel13.png" class="d-block w-100" alt="" />
            </div>
            <div class="carousel-item">
                <img src="../assets/img/IDEL/Carousel/idel14.png" class="d-block w-100" alt="" />
            </div>
        </div>
        <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
        >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
        >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'CarouselIdel',
}
</script>