<template>
    <div>
        <header
            class="
                page-header-ui page-header-ui-dark
                bg-img-cover
                overlay overlay-primary overlay-90
            "
            :style="
                img
            "
            >
            <div class="page-header-ui-content py-5 position-relative">
                <div class="container px-5">
                <div class="row gx-5 justify-content-center">
                    <div class="col-xl-8 col-lg-10 text-center title">
                    <h1 class="page-header-ui-title">{{title}}</h1>
                    <p class="page-header-ui-text mb-5">
                        {{description}}
                    </p>
                    </div>
                </div>
                </div>
            </div>
            <div class="svg-border-rounded text-white">
                <!-- Rounded SVG Border-->
                <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 144.54 17.34"
                preserveAspectRatio="none"
                fill="currentColor"
                >
                <path
                    d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"
                ></path>
                </svg>
            </div>
        </header>
    </div>
</template>

<script>

export default {
    name: "Header",

    props: {
        title: String,
        description: String,
        img: String
    }
};
</script>

<style scoped>
    .title {
    animation-duration: 2s;
    animation-name: slidein;
  }

  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }
</style>
