<template>
  <div>
    <div id="layoutDefault_footer">
      <footer class="footer pt-10 pb-5 mt-auto bg-dark footer-dark">
        <div class="container px-5">
          <div class="row gx-3">
            <div>
              <div class="row gx-3 justify-items-center">
                <div>
                  <div class="footer-brand">Inmobiliaria del Sol</div>
                  <div class="icon-list-social mb-5">
                    <a class="icon-list-social-link" href="https://www.facebook.com/IFSinmobiliaria/" target="_blank" rel="noopener noreferrer">
                      <i class="fab fa-facebook"></i>
                      IFSinmobiliaria 
                    </a>
                  </div>
                </div>
                <div class="col-md-6 mb-5 mb-lg-0">
                  <div class="text-uppercase-expanded text-s mb-4">Dirección</div>
                  <p>Blvd Luis Donaldo Colosio #895 e/ Josefa Oritz de Dominguez y Palma,</p>
                  <p>Col. Arboledas, CP. 23099.</p>
                  <p>La Paz, Baja California Sur, México</p>
                </div>
                <div class="col-md-6 mb-5 mb-lg-0">
                  <div class="text-uppercase-expanded text-s mb-4">Teléfonos</div>
                  <ul class="list-unstyled mb-0">
                    <div class="row">
                      <div class="col">
                        <p class="text-uppercase-expanded text-xs mt-2 mb-2">Oficina</p>
                        <li class="mb-2">
                          <a href="tel:612-146-2744">612 146 2744</a>
                        </li>
                        <li class="mb-2">
                          <a href="tel:612-125-1765">612 125 1765</a>
                        </li>
                      </div>
                      <div class="col">
                        <p class="text-uppercase-expanded text-xs mt-2 mb-2">Celulares</p>
                        <li class="mb-2">
                          <a href="tel:612-348-9165">612 348 9165</a>
                        </li>
                        <li class="mb-2">
                          <a href="tel:612-117-2644">612 117 2624</a>
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-5" />
          <div class="row gx-5 align-items-center">
            <div class="col-md-6 small">Copyright &copy; Inmobiliaria del Sol 2021</div>
            <div class="col-md-6 text-md-end small">
              <a href="assets/docs/aviso-de-privacidad.pdf" target="blank">Aviso de privacidad</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>