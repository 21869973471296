<template>
    <div>
        <Header title="Contacto" description="Estamos aquí para ayudarte" />
        <section class="bg-white py-10">
            <div class="container px-5">
                <div class="row gx-5 justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2>Contáctanos</h2>
                        <p>Nos comunicaremos contigo lo más pronto posible para responder tus dudas</p>
                    </div>
                    <form action="https://formsubmit.co/fracdelsol@hotmail.com" method="POST">
                        <div class="row gx-5 mb-4">
                            <div class="col-md-6">
                                <label class="text-dark mb-2 mt-2" for="inputName">Nombre completo</label>
                                <input
                                    class="form-control py-4"
                                    id="inputName"
                                    type="text"
                                    placeholder="Nombre completo"
                                    name="Nombre"
                                    v-model="message.name"
                                    required
                                />
                            </div>
                            <div class="col-md-6">
                                <label
                                    class="text-dark mb-2 mt-2"
                                    for="inputEmail"
                                >Correo electrónico</label>
                                <input
                                    class="form-control py-4"
                                    id="inputEmail"
                                    type="email"
                                    placeholder="email@ejemplo.com"
                                    name="Email"
                                    required
                                />
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="text-dark mb-2" for="inputMessage">Mensaje</label>
                            <textarea
                                class="form-control py-3"
                                id="inputMessage"
                                type="text"
                                placeholder="Tu mensaje..."
                                rows="4"
                                name="Mensaje"
                                required
                            ></textarea>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-primary mt-4" type="submit">Enviar</button>
                        </div>
                        <div>
                            <input
                                type="hidden"
                                name="_subject"
                                :value="'Correo de ' + message.name + ' - ' + message.date"
                            />
                            <input
                                type="hidden"
                                name="_next"
                                value="http://www.inmobiliariadelsol.com.mx/thankyou"
                            />
                            <input type="hidden" name="_template" value="box" />
                        </div>
                    </form>
                </div>
            </div>
            <Curve color="#f2f6fc" />
        </section>
        <section class="bg-light py-10">
            <div class="container px-5">
                <div class="text-center mb-5">
                    <h2>¿Dónde nos puedes encontrar?</h2>
                </div>
                <div class="row g-0 align-items-center">
                    <div class="col-lg-6 mb-5 mb-lg-0">
                        <div class="card pricing">
                            <div class="card-body p-5">
                                <Map
                                location="https://maps.google.com/maps?width=300&amp;height=300&amp;hl=en&amp;q=Blvd%20Luis%20Donaldo%20Colosio%20%23895%20la%20paz+(Inmobiliaria%20del%20Sol)&amp;ie=UTF8&amp;t=&amp;z=15&amp;iwloc=B&amp;output=embed" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card pricing pricing-right bg-gray-800">
                            <div class="card-body p-5 text-white">
                                <p>Blvd Luis Donaldo Colosio #895 e/ Josefa Oritz de Dominguez y Palma,</p>
                                <p>Col. Arboledas, CP. 23099.</p>
                                <p>La Paz, Baja California Sur, México</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container px-5 mt-5 pt-3">
                <div class="text-center mb-5">
                    <h2>¿Otra forma de contactarnos?</h2>
                </div>
                <div class="row gx-5 mb-5">
                    <div class="col-lg-4 mb-5">
                        <div
                            class="card card-link border-bottom-0 border-start-0 border-end-0 border-top-lg border-primary lift text-center o-visible h-100"
                        >
                            <div class="card-body">
                                <div
                                    class="icon-stack icon-stack-xl bg-primary-soft text-primary mb-4 mt-n5 z-1 shadow"
                                >
                                    <i class="fas fa-building"></i>
                                </div>
                                <h5>Oficina</h5>
                                <div class="d-flex flex-column">
                                    <a href="tel:612-146-2744">612 146 2744</a>
                                    <a href="tel:612-125-1765">612 125 1765</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-5">
                        <div
                            class="card card-link border-bottom-0 border-start-0 border-end-0 border-top-lg border-secondary lift text-center o-visible h-100"
                        >
                            <div class="card-body">
                                <div
                                    class="icon-stack icon-stack-xl bg-secondary-soft text-secondary mb-4 mt-n5 z-1 shadow"
                                >
                                    <i class="fas fa-mobile"></i>
                                </div>
                                <h5>Celulares</h5>
                                <div class="d-flex flex-column">
                                    <a href="tel:612-348-9165">612 348 9165</a>
                                    <a href="tel:612-117-2644">612 117 2624</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-5">
                        <div
                            class="card card-link border-bottom-0 border-start-0 border-end-0 border-top-lg border-teal lift text-center o-visible h-100"
                        >
                            <div class="card-body">
                                <div
                                    class="icon-stack icon-stack-xl bg-teal-soft text-teal mb-4 mt-n5 z-1 shadow"
                                >
                                    <i class="fas fa-envelope"></i>
                                </div>
                                <h5>Correo electónico</h5>
                                <a href="mailto:fraccdelsol@hotmail.com">fraccdelsol@hotmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Curve color="#212832"/>
        </section>
    </div>
</template>

<script>
import Header from "../components/Header.vue";
import Curve from "../components/Curve.vue"
import Map from "../components/Map.vue";

export default {
    name: 'Contact',
    components: {
        Header,
        Curve,
        Map
    },

    mounted() {
        window.scrollTo(0,0);
        const date = new Date();
        const month = String(date).slice(4, 7);
        const dayNYear = String(date).slice(7, 15);
        const espMonth = this.getMonthInSpanish(month);
        this.message.date = espMonth + dayNYear;
    },

    data() {
        return {
            message: {
                name: '',
                date: ''
            }
        }
    },

    methods: {
        getMonthInSpanish: function (month) {
            switch (month) {
                case 'Jan': {
                    return 'Enero'
                }
                case 'Feb': {
                    return 'Febrero'
                }
                case 'Mar': {
                    return 'Marzo'
                }
                case 'Apr': {
                    return 'Abril'
                }
                case 'May': {
                    return 'Mayo'
                }
                case 'Jun': {
                    return 'Junio'
                }
                case 'Jul': {
                    return 'Julio'
                }
                case 'Aug': {
                    return 'Agosto'
                }
                case 'Sep': {
                    return 'Septiembre'
                }
                case 'Oct': {
                    return 'Octubre'
                }
                case 'Nov': {
                    return 'Noviembre'
                }
                case 'Dec': {
                    return 'Diciembre'
                }
            }
        }
    }

}
</script>

<style>
#gmap_canvas img {
    max-width: none !important;
    background: none !important;
}

.myMap {
    width: 100%;
    position: relative;
}
</style>