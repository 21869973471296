<template>
  <div>
    <header
      class="
        page-header-ui-dark
        bg-img-cover
        overlay overlay-primary overlay-90
      "
    >
      <div class="page-header-ui-content position-relative">
          <div class="row gx-5 justify-content-center">
              <img
                class="logo_home"
                :src="inmdelsol"
                alt="INMOBILIARIA DEL SOL"
              />
          </div>
      </div>
      <Curve color="#fff"/>
    </header>

    <section class="bg-white py-10">
      <br><br>
      <div class="container px-5">
        <div class="row gx-5 align-items-center justify-content-center">
          <div class="col-md-9 col-lg-6" data-aos="slide-left">
            <div class="mb-4">
              <div class="content-skewed content-skewed-left">
                <a class="card card-portfolio h-100 link" v-on:click="$event=>goTo('LosCirios')">
                  <img
                    class="img-fluid shadow-lg rounded-3"
                    :src="cirios" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-5">
              <h2 class="link" v-on:click="goTo('LosCirios')">Conoce Los Cirios</h2>
              <p class="lead">
                Conjunto de 10 residencias a un costado de UABCS, cerca de
                plazas comerciales y servicio médico.
                Viviendas de 2 plantas, 3 recamaras. Calidad confort, distribucion y
                garantias con ecotecnologias en accesorios.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Curve color="#f2f6fc" />
    </section>
    <br><br>

    <section class="bg-light py-10">
      <div class="container px-5">
        <div class="row gx-5 align-items-center justify-content-center">
          <div class="col-lg-6">
            <div class="mb-5">
              <h2 class="link" v-on:click="goTo('BellaCalifornia')">Conoce Bella California</h2>
              <p class="lead">
                Fraccionamiento ubicado al sur de la ciudad de La Paz, 8 etapas,
                viviendas de interes social medio. Viviendas de 1 y 2 plantas, 2
                y 3 recamaras. Calidad, confort, distribucion y garantias con
                ecotecnologias en accesorios
              </p>
            </div>
          </div>
          <div class="col-md-9 col-lg-6" data-aos="slide-left">
            <div class="mb-4">
              <div class="content-skewed content-skewed-left">
                <a class="card card-portfolio h-100 logos link" v-on:click="$event=>goTo('BellaCalifornia')">
                  <img
                    class="img-fluid shadow-lg rounded-3"
                    :src="logoBellaCalifornia"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Curve color="#fff" />
    </section>

    <section class="bg-white py-10">
      <div class="container px-5">
        <div class="row gx-5 justify-content-center">
          <div class="col-lg-10">
            <div class="row gx-5">
              <div class="inside-one">
                <h2>Explora nuestra página</h2>
                <br />
                <div class="inside-two">
                  <router-link
                    class="card card-portfolio mb-5 portfolio buttons"
                    to="/portafolio"
                  >
                    <h3 class="text-port">Portafolio</h3>
                  </router-link>
                  <div class="inline-three">
                    <router-link class="card card-portfolio mb-5 contact buttons" to="/contact">
                      <h3 class="text-buttons">Contacto</h3>
                    </router-link>
                    <router-link class="card card-portfolio mb-5 about buttons" to="/about">
                      <h3 class="text-buttons">Nosotros</h3>
                    </router-link>
                  </div>
                </div>
                <router-link class="card card-portfolio mb-5 financing buttons" to="/financing">
                  <h3 class="text-buttons">Financiamientos</h3>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Curve color="#212832" />
    </section>
  </div>
</template>

<script>
import router from "../router";
import Curve from "../components/Curve.vue";

import logoBellaCalifornia from "@/assets/img/LOGO BELLA CALIFORNIA.jpg";
import inmdelsol from "@/assets/img/LOGO.png";
import cirios from "@/assets/img/Los Cirios.jpg";

export default {
  name: "Home",
  components: {
    Curve,
  },

  data() {
    return {
      logoBellaCalifornia,
      cirios,
      inmdelsol,
    };
  },

  methods: {
    goTo: function (model) {
      router.push({ name: model });
    },
  },
};
</script>

<style scoped>

.link {
  cursor: pointer;
}
.logo_home {
  width: 60vw;
  height: auto;
  padding: 10vmin;
}

.inside-one {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  align-items: center;
}

.inside-two {
  display: flex;
  flex-direction: row;
}

.card-portfolio {
  background-color: #a8dadc;
  margin-right: 20px;
  margin-left: 20px;
}

.portfolio {
  width: 300px;
  height: 400px;
  justify-content: center;
  text-decoration: none;
  transition: 0.25s ease-in-out;
  position: inline-block;
  text-align: center;
}

.contact {
  width: 400px;
  height: 240px;
  justify-content: center;
  text-decoration: none;
  transition: 0.25s ease-in-out;
}

.about {
  width: 400px;
  height: 120px;
  justify-content: center;
  text-decoration: none;
  transition: 0.25s ease-in-out;
}

.financing {
  width: 740px;
  height: 120px;
  justify-content: center;
  text-decoration: none;
  transition: 0.25s ease-in-out;
}

.text-buttons {
    color: black;
  }

@media (min-width: 992px) {
  .portfolio {
    background-image: url("../assets/img/AMBIA/perspective.jpg");
    background-size: cover;
    background-position: center;
    opacity: 0.9;
  }

  .buttons:hover {
    opacity: 0.5;
  }

  .text-port {
    color: whitesmoke;
  }
}

@media (max-width: 991px) {
  .inside-one {
    display: block;
    align-content: center;
    text-align: center;
    align-items: center;
  }

  .inside-two {
    display: block;
  }

  .inside-three {
    display: block;
    align-content: center;
    text-align: center;
    align-items: center;
  }

  .card-portfolio {
    margin-right: auto;
    margin-left: auto;
  }

  .financing {
    width: 80%;
    height: 80px;
  }
  .contact {
    width: 80%;
    height: 80px;
  }
  .about {
    width: 80%;
    height: 80px;
  }
  .portfolio {
    width: 80%;
    height: 80px;
  }

  .portfolio:hover {
    background-color: #acb8ff;
  }

}

@keyframes slidin {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
</style>
