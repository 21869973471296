<template>
  <div>
    <Header
      title="Portafolio"
      description="Aqui podrás explorar los desarrollos en los que estamos trabajando."
      img="background-image: url('assets/img/KALAL/perspective1.jpg')"
    />

    <section class="bg-white py-10">
      <div class="text-center">
        <h2>Desarrollos actuales</h2>
        <br />
      </div>
      <div class="container px-5">
        <div class="row gx-5">
          <div class="col-md-6 mb-5">
            <a class="card card-portfolio h-100 logos link" v-on:click="$event=>goTo('BellaCalifornia')">
              <img class="card-img-top" :src="logoBellaCalifornia" alt="..." />
            </a>
          </div>
          <div class="col-md-6 mb-5">
            <a class="card card-portfolio h-100 logos link" v-on:click="$event=>goTo('LosCirios')">
              <img class="card-img-top" :src="cirios" alt="..." />
            </a>
          </div>
        </div>
      </div>
      <Curve color="#f2f6fc" />
    </section>

    <section class="bg-light py-10">
      <div class="text-center">
        <h2>Desarrollos terminados</h2>
        <br />
      </div>
      <div class="container px-5">
        <div class="row gx-5">
          <div class="col-md-6 mb-5">
            <a class="card card-portfolio h-100 logos">
              <img class="card-img-top" :src="alamos" alt="..." />
            </a>
          </div>
          <div class="col-md-6 mb-5">
            <a class="card card-portfolio h-100 logos">
              <img class="card-img-top" :src="terranova" alt="..." />
            </a>
          </div>
          <div class="col-md-6 mb-5">
            <a class="card card-portfolio h-100 logos">
              <img class="card-img-top" :src="sierra" alt="..." />
            </a>
          </div>
          <div class="col-md-6 mb-5">
            <a class="card card-portfolio h-100 logos">
              <img class="card-img-top" :src="mediterraneo" alt="..." />
            </a>
          </div>
        </div>
      </div>
    <Curve color="#212832" />
    </section>
    
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Curve from "../components/Curve.vue";
import router from "../router/index";

import logoBellaCalifornia from "@/assets/img/LOGO BELLA CALIFORNIA.jpg";
import ambia from "@/assets/img/AMBIA/perspective.jpg";
import kalal from "@/assets/img/KALAL/perspective1.jpg";
import idel from "@/assets/img/IDEL/perspective.jpg";
import video from "@/assets/img/video.mp4";
import etapa1 from "@/assets/img/Etapas/Etapa1.jpg";
import etapa2 from "@/assets/img/Etapas/Etapa2.jpg";
import etapa3 from "@/assets/img/Etapas/Etapa3.jpg";
import etapa4 from "@/assets/img/Etapas/Etapa4.jpg";
import etapa5 from "@/assets/img/Etapas/Etapa5.jpg";
import alamos from "@/assets/img/logo alamos.jpg";
import terranova from "@/assets/img/terranova.jpg";
import sierra from "@/assets/img/SierraLago.png";
import mediterraneo from "@/assets/img/logo mediterraneo.jpg";
import cirios from "@/assets/img/Los Cirios.png";

export default {
  name: "Portafolio",
  components: {
    Header,
    Curve,
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      logoBellaCalifornia,
      ambia,
      kalal,
      idel,
      video,
      etapa1,
      etapa2,
      etapa3,
      etapa4,
      etapa5,
      alamos,
      terranova,
      sierra,
      mediterraneo,
      cirios,
    };
  },

  methods: {
    goTo: function (model) {
      router.push({ name: model });
    },
  },
};
</script>

<style scoped>

.link {
  cursor: pointer;
}

.logos {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
</style>
