<template>
    <div class="col-lg-4 mb-5 mb-lg-0">
        <a class="card lift h-100" href="">
            <!-- <div class="card-flag card-flag-dark card-flag-top-right">{{ title }}</div> -->
            <img
                class="card-img-top"
                :src="img"
                alt="..."
            />
            <div class="card-body">
                <h3 class="text-primary mb-0">{{ title }}</h3>
                <div class="small text-gray-800 fw-500">
                    <div class="d-inline">
                        <i class="fa-solid fa-house"></i> Plantas: {{ floors }}
                    </div>
                    <br>
                    <div class="d-inline">
                        <i class="fa-solid fa-bed"></i> {{ rooms }}  | <i class="fa-solid fa-bath"></i> {{ baths }} | <i class="fa-solid fa-car"></i> {{parking}}
                    </div>
                    <br>
                    <div class="d-inline">
                        <div class="text-gray-800 fw-500"><i class="fa-solid fa-ruler"></i> Terreno {{ area }} m<sup>2</sup> - Construcción {{ construction }} m<sup>2</sup></div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "HouseCard",

    props: {
        title: String,
        floors: Number,
        rooms: Number,
        baths: String,
        parking: Number,
        area: Number,
        construction: Number,
        img: String
    }
};
</script>