<template>
  <div class="d-flex flex-row justify-content-center align-items-center">
    <div class="btns previous" v-on:click="changeVideo"><i class="fa-solid fa-chevron-left"></i></div>
    <div>
        <div class="container-videos">
            <div v-if="video1">
                <video
                class="video-inm-del-sol"
                id="video-1"
                src="assets/video/video1.mp4"
                controls
                ></video>
            </div>
            <div v-if="video2">
                <video
                id="video-2"
                class="video-inm-del-sol"
                src="assets/video/video2.mp4"
                controls
                ></video>
            </div>
        </div>
    </div>
    <div class="btns next" v-on:click="changeVideo"><i class="fa-solid fa-chevron-right"></i></div>
  </div>
</template>

<script>
export default {
  name: "CarouselVideos",
  data() {
      return {
          video1: true,
          video2: false
      }
  },
  methods: {
      changeVideo: function() {
          const video1 = document.querySelector('#video-1');
          const video2 = document.querySelector('#video-2');
          if (this.video1) {
              video1.pause();
              video1.currentTime = 0;
              this.video1 = false;
              this.video2 = true;
          } else if (this.video2) {
              video2.pause();
              video2.currentTime = 0;
              this.video2 = false;
              this.video1 = true;
          }
      }
  }
};
</script>

<style>
.video-inm-del-sol {
  width: 100%;
  object-fit: cover;
}

.btns {
    cursor: pointer;
}

.previous {
    margin-right: 5px;
}

.next {
    margin-left: 5px;
}

@media (max-width: 460px) {
    .video-inm-del-sol {
        height: 100%;
    }
}
@media (min-width: 461px) and (max-width: 1007px) {
    .video-inm-del-sol {
        height: 400px;
    }
}
@media (min-width: 1008px) { 
    .video-inm-del-sol {
        height: 500px;
    }
    .previous {
        margin-right: 20px;
    }

    .next {
        margin-left: 20px;
    }
}
</style>
