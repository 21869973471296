<template>
  <div>
    <Header
      title="Esquemas de Financiamiento"
      description="¿Cómo puedo adquirir mi vivienda? Conoce los diversos esquemas de credito para adquirir tu vivienda.
            (FOVISSSTE, IFONAVIT, BANCA COMERCIAL)"
      img
    />
    <section class="bg-white py-10">
      <div class="container px-5">
        <div class="row gx-5">
          <div class="col-lg-4 col-xl-3 mb-5">
            <div class="card">
              <div class="list-group list-group-flush small">
                <button class="list-group-item list-group-item-action p-3" v-on:click="changeInfo('infonavit')">
                  INFONAVIT
                </button>
                <button class="list-group-item list-group-item-action p-3" v-on:click="changeInfo('foviste')">
                  FOVISSSTE
                </button>
                <button class="list-group-item list-group-item-action p-3" v-on:click="changeInfo('creditos')">
                  Créditos Hipotecarios
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-xl-9">
            <div v-if="info.name == 'infonavit'" class="card mb-5">
              <div class="card-header d-flex justify-content-between">
                <div class="me-2 text-dark">
                  INFONAVIT
                </div>
              </div>
              <div class="card-body">
                <p>
                  El Instituto del Fondo Nacional de la Vivienda para los Trabajadores (INFONAVIT)
                  es una institución mexicana tripartitadonde participa el sector obrero, el sector 
                  empresarial y el gobierno, dedicada a otorgar un crédito para la obtención de 
                  vivienda a los trabajadores y brindar rendimientos al ahorro que está en el Fondo 
                  Nacional de Vivienda para las pensiones de retiro.
                </p>
                <div class="img_container">
                    <img class="logo_financing" :src="infonavit" alt="INFONAVIT">
                </div>
                
                <hr />
                <p class="mb-0 small">
                  Para más información visite el siguiente enlace
                  <br />
                  <a target="_blank" rel="noopener noreferrer" href="https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/trabajadores/!ut/p/z1/hY7LDoIwEEW_hQVbZoBoqruaIGqIOyN2Y4pWqOmDlEr8fPGxFJ3dzD3nZoBBCczwXtbcS2u4GvYDmx4TgpgvMN7mZEWQLrN1NivSJI8R9v8ANsQ4MvTpsxcy1rCLP8CPjg2wWtnq_S41VUpqYE5chBMuurnh3HjfzkMMURptK6kkd5Kfheqsik5WR_oe4je5sZ2HclSCVpd4nai-oEHwAOfCfOQ!/dz/d5/L2dBISEvZ0FBIS9nQSEh/">
                    Más información
                  </a>
                </p>
              </div>
            </div>

            <div v-if="info.name == 'foviste'" class="card mb-5">
              <div class="card-header d-flex justify-content-between">
                <div class="me-2 text-dark">
                  FOVISSSTE
                </div>
              </div>
              <div class="card-body">
                <p>
                  El Fondo de la Vivienda es el Órgano Desconcentrado del Instituto de Seguridad y 
                  Servicios Sociales de los Trabajadores del Estado (ISSSTE), cuyo objeto es el de 
                  establecer y operar el sistema de Financiamiento para el Otorgamiento de los 
                  Préstamos Hipotecarios a los Trabajadores Derechohabientes del ISSSTE.
                </p>
                <div class="img_container">
                    <img class="logo_financing" :src="fovissste" alt="FOVISSSTE">
                </div>
                
                <hr />
                <p class="mb-0 small">
                  Para más información visite el siguiente enlace
                  <br />
                  <a href="https://www.gob.mx/fovissste" target="_blank" rel="noopener noreferrer">
                    Más información
                  </a>
                </p>
              </div>
            </div>

            <div v-if="info.name == 'creditos'" class="card mb-5">
              <div class="card-header d-flex justify-content-between">
                <div class="me-2 text-dark">
                    Créditos Hipotecarios
                </div>
              </div>
              <div class="card-body">
                <p>
                  El crédito es una operación financiera donde una persona presta una cantidad 
                  determinada de dinero a otra persona llamada "deudor", en la cual este último 
                  se compromete a devolver la cantidad solicitada en el tiempo o plazo definido 
                  según las condiciones establecidas para dicho préstamo más los intereses 
                  devengados, seguros y costos asociados si los hubiera.
                </p>
                <div class="img_container">
                    <img class="logo_financing" :src="creditosHipotecarios" alt="Créditos Hipotecarios">
                </div>

                <hr>
                <p class="mb-0 small">
                  Para más información visite el siguiente enlace
                  <br />
                  <a href="https://www.gob.mx/shf" target="_blank" rel="noopener noreferrer">
                    Más información
                  </a>
                </p>
            
              </div>
            </div>

          </div>
        </div>
      </div>
      <Curve color="#212832" />
    </section>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Curve from "../components/Curve.vue";

import infonavit from "@/assets/img/LOGO INFONAVIT.png";
import fovissste from "@/assets/img/LOGO FOVISSSTE.jpg";
import creditosHipotecarios from "@/assets/img/LOGO_SHF.png";

export default {
  name: "Financing",
  components: { Curve, Header },

  mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      infonavit,
      fovissste,
      creditosHipotecarios,

      info: {
          name: "infonavit",
      }
    };
  },

  methods: {
        changeInfo: function(name) {
            this.info.name = name
        }
    }
};
</script>

<style scoped>
.img_container {
    align-content: center;
    text-align: center;
}

.logo_financing {
    width: 40%;
    height: auto;
    border-radius: 5px;
}

.card-fin {
  text-decoration: none;
  color: rgb(105, 105, 105);
}

.center-fin {
  align-content: center;
  text-align: center;
  justify-content: center;
}

</style>
