<template>
    <section class="bg-light py-10">
        <div class="container px-5">
            <div class="row gx-5 justify-content-center">
                <div class="col-lg-8 text-center">
                    <h1>¡Gracias por contactarnos!</h1>
                    <p>Alguien de nuestro equipo revisará tu mensaje y se pondrá en contacto contigo lo más pronto posible</p>
                    <router-link to="/">Regresar a la página de inicio</router-link>
                </div>
            </div>
        </div>
        <Curve color="#212832"/>
    </section>
</template>

<script>
import Curve from "../components/Curve.vue";
export default {
    name: "ThankYou",
    components: { Curve }
}
</script>