<template>
    <div>
        <header
        class="
          page-header-ui-dark
          bg-img-cover
          overlay overlay-primary overlay-90
        "
      >
        <div class="page-header-ui-content position-relative">
            <div class="row gx-5 justify-content-center">
                <img
                  class="logo_home"
                  src="assets/img/BELLA CALIFORNIA LOGO.png"
                  alt="BELLA CALIFORNIA"
                />
            </div>
        </div>
        <Curve color="#fff"/>
      </header>
      <section class="bg-white py-10 logo-container">
        <div class="container px-5">
          <div class="row gx-5 align-items-center justify-content-center">
            <div class="col-md-9 col-lg-6" data-aos="slide-left">
              <div class="mb-4">
                <div class="content-skewed content-skewed-left">
                  <img
                    class="img-fluid"
                    :src="logoBellaCalifornia"
                    alt="BELLA CALIFORNIA"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mb-5">
                <h2>Bella California</h2>
                <p class="lead">
                  Fraccionamiento ubicado al sur de la ciudad de La Paz, 8 etapas,
                  viviendas de interes social medio. Viviendas de 1 y 2 plantas, 2
                  y 3 recamaras. Calidad, confort, distribucion y garantias con
                  ecotecnologias en accesorios
                </p>
              </div>
            </div>
          </div>
        </div>
        <br /><br /><br />
  
        <div class="container px-5">
          <div class="row g-0 align-items-center">
            <div class="col-lg-6">
              <div class="card pricing pricing-left bg-gray-800">
                <div class="card-body p-5 text-white">
                  <p>Fraccionamiento Bella California</p>
                  <p>La Paz, Baja California Sur, México</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-5 mb-lg-0">
              <div class="card pricing">
                <div class="card-body p-5">
                  <MapBellaCal />
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <Curve color="#f2f6fc" />
      </section>
  
      <section class="bg-light py-10">
        <div class="houseModels">
          <div class="container px-5">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h2 class="mb-0">Modelos de casas</h2>
            </div>
            <div class="row gx-5">
              <HouseCard
                title="AMBIA"
                :floors="2"
                :parking="2"
                :rooms="3"
                baths="2 ½"
                :area="155"
                :construction="83"
                :img="ambia"
                v-on:click="goTo('Ambia')"
              />
              <HouseCard
                title="KALAL"
                :floors="1"
                :parking="2"
                :rooms="2"
                baths="1"
                :area="140"
                :construction="60.89"
                :img="kalal"
                v-on:click="goTo('Kalal')"
              />
              <HouseCard
                title="IDEL"
                :floors="2"
                :parking="2"
                :rooms="2"
                baths="1 ½"
                :area="140"
                :construction="64.17"
                :img="idel"
                v-on:click="goTo('Idel')"
              />
            </div>
          </div>
        </div>
        <Curve color="#fff" />
      </section>
  
      <!--Linea de Tiempo-->
      <section class="bg-white logo-container">
        <div class="container">
          <div class="text-center py-10">
            <h2>Etapas de Desarrollo</h2>
          </div>
          <ul class="timeline">
            <li>
              <div class="timeline-image">
                <img class="rounded-circle img-fluid" :src="etapa1" alt="Etapa" />
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h4 class="subheading">Etapa 1</h4>
                  <h4>Agosto 2018 - Abril 2019</h4>
                </div>
                <div class="timeline-body">
                  <p class="text-muted">60 viviendas</p>
                </div>
              </div>
            </li>
            <li class="timeline-inverted">
              <div class="timeline-image">
                <img class="rounded-circle img-fluid" :src="etapa2" alt="Etapa" />
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h4 class="subheading">Etapa 2</h4>
                  <h4>Junio 2019 - Abril 2020</h4>
                </div>
                <div class="timeline-body">
                  <p class="text-muted">61 viviendas</p>
                </div>
              </div>
            </li>
            <li>
              <div class="timeline-image">
                <img class="rounded-circle img-fluid" :src="etapa3" alt="Etapa" />
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h4 class="subheading">Etapa 3</h4>
                  <h4>Mayo 2020 - Febrero 2021</h4>
                </div>
                <div class="timeline-body">
                  <p class="text-muted">63 viviendas</p>
                </div>
              </div>
            </li>
            <li class="timeline-inverted">
              <div class="timeline-image">
                <img class="rounded-circle img-fluid" :src="etapa4" alt="Etapa" />
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h4 class="subheading">Etapa 4</h4>
                  <h4>Febrero 2021 - Diciembre 2021</h4>
                </div>
                <div class="timeline-body">
                  <p class="text-muted">48 viviendas</p>
                </div>
              </div>
            </li>
            <li>
              <div class="timeline-image">
                <img class="rounded-circle img-fluid" :src="etapa5" alt="Etapa" />
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h4 class="subheading">Etapa 5</h4>
                  <h4>Diciembre 2021 - A la fecha</h4>
                </div>
                <div class="timeline-body">
                  <p class="text-muted">78 viviendas</p>
                </div>
              </div>
            </li>
            <li class="timeline-inverted">
              <div class="timeline-image">
                <h4 class="fin_timeline">
                  Aparta tu casa
                  <br />
                  con nosotros
                </h4>
              </div>
            </li>
          </ul>
        </div>
        <br /><br />
        <Curve color="#f2f6fc" />
      </section>
  
      <section class="bg-light py-10">
        <div class="container text-center">
          <CarouselVideos />
        </div>
        <Curve color="#fff" />
      </section>
  
      <section class="bg-white py-10">
        <div class="text-center">
          <h2>Proyectos anteriores</h2>
          <br />
        </div>
        <div class="container px-5">
          <div class="row gx-5">
            <div class="col-md-6 mb-5">
              <a class="card card-portfolio h-100 logos">
                <img class="card-img-top" :src="alamos" alt="..." />
              </a>
            </div>
            <div class="col-md-6 mb-5">
              <a class="card card-portfolio h-100 logos">
                <img class="card-img-top" :src="terranova" alt="..." />
              </a>
            </div>
            <div class="col-md-6 mb-5">
              <a class="card card-portfolio h-100 logos">
                <img class="card-img-top" :src="sierra" alt="..." />
              </a>
            </div>
            <div class="col-md-6 mb-5">
              <a class="card card-portfolio h-100 logos">
                <img class="card-img-top" :src="mediterraneo" alt="..." />
              </a>
            </div>
          </div>
        </div>
        <Curve color="#212832" />
      </section>
    </div>
  </template>
  
  <script>
  import HouseCard from "../components/HouseCard.vue";
  import Curve from "../components/Curve.vue";
  import router from "../router/index";
  import MapBellaCal from "../components/MapBellaCal.vue";
  import CarouselVideos from "../components/CarouselVideos.vue";
  
  import logoBellaCalifornia from "@/assets/img/LOGO BELLA CALIFORNIA.jpg";
  import ambia from "@/assets/img/AMBIA/perspective.jpg";
  import kalal from "@/assets/img/KALAL/perspective1.jpg";
  import idel from "@/assets/img/IDEL/perspective.jpg";
  import video from "@/assets/img/video.mp4";
  import etapa1 from "@/assets/img/Etapas/Etapa1.jpg";
  import etapa2 from "@/assets/img/Etapas/Etapa2.jpg";
  import etapa3 from "@/assets/img/Etapas/Etapa3.jpg";
  import etapa4 from "@/assets/img/Etapas/Etapa4.jpg";
  import etapa5 from "@/assets/img/Etapas/Etapa5.jpg";
  import alamos from "@/assets/img/logo alamos.jpg";
  import terranova from "@/assets/img/terranova.jpg";
  import sierra from "@/assets/img/SierraLago.png";
  import mediterraneo from "@/assets/img/logo mediterraneo.jpg";
  
  export default {
    name: "Portafolio",
    components: {
      HouseCard,
      Curve,
      MapBellaCal,
      CarouselVideos,
    },
  
    mounted() {
      window.scrollTo(0, 0);
    },
  
    data() {
      return {
        logoBellaCalifornia,
        ambia,
        kalal,
        idel,
        video,
        etapa1,
        etapa2,
        etapa3,
        etapa4,
        etapa5,
        alamos,
        terranova,
        sierra,
        mediterraneo,
      };
    },
  
    methods: {
      goTo: function (model) {
        router.push({ name: model });
      },
    },
  };
  </script>
  
  <style scoped>

  .logo_home {
    width: 70vw;
    height: auto;
    padding: 10vmin;
    animation-duration: 3s;
    animation-name: backgroundin;
  }
  
  .timeline {
    position: relative;
    padding: 0;
    list-style: none;
  }
  
  .timeline:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    content: "";
    background-color: #e9ecef;
  }
  .timeline > li {
    position: relative;
    min-height: 50px;
    margin-bottom: 50px;
  }
  .timeline > li:after,
  .timeline > li:before {
    display: table;
    content: " ";
  }
  .timeline > li:after {
    clear: both;
  }
  .timeline > li .timeline-panel {
    position: relative;
    float: right;
    width: 100%;
    padding: 0 20px 0 100px;
    text-align: left;
  }
  .timeline > li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
  }
  .timeline > li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
  }
  .timeline > li .timeline-image {
    position: absolute;
    z-index: 100;
    left: 0;
    width: 80px;
    height: 80px;
    margin-left: 0;
    text-align: center;
    color: white;
    border: 7px solid #e9ecef;
    border-radius: 100%;
    background-color: #a8dadc;
  }
  .timeline > li .timeline-image h4,
  .timeline > li .timeline-image .h4 {
    font-size: 10px;
    line-height: 14px;
    margin-top: 12px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left;
  }
  .timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
  }
  .timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
  }
  .timeline > li:last-child {
    margin-bottom: 0;
  }
  .timeline .timeline-heading h4,
  .timeline .timeline-heading .h4 {
    margin-top: 0;
    color: inherit;
  }
  .timeline .timeline-heading h4.subheading,
  .timeline .timeline-heading .subheading.h4 {
    text-transform: none;
  }
  .timeline .timeline-body > ul,
  .timeline .timeline-body > p {
    margin-bottom: 0;
  }
  
  .fin_timeline {
    color: white;
  }
  
  .logo-container {
    text-align: center;
    padding-top: 50px;
  }
  
  .logos {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  
  @media (min-width: 768px) {
    .timeline:before {
      left: 50%;
    }
    .timeline > li {
      min-height: 100px;
      margin-bottom: 100px;
    }
    .timeline > li .timeline-panel {
      float: left;
      width: 41%;
      padding: 0 20px 20px 30px;
      text-align: right;
    }
    .timeline > li .timeline-image {
      left: 50%;
      width: 100px;
      height: 100px;
      margin-left: -50px;
    }
    .timeline > li .timeline-image h4,
    .timeline > li .timeline-image .h4 {
      font-size: 13px;
      line-height: 18px;
      margin-top: 16px;
    }
    .timeline > li.timeline-inverted > .timeline-panel {
      float: right;
      padding: 0 30px 20px 20px;
      text-align: left;
    }
  }
  
  @media (min-width: 992px) {
    .timeline > li {
      min-height: 150px;
    }
    .timeline > li .timeline-panel {
      padding: 0 20px 20px;
    }
    .timeline > li .timeline-image {
      width: 150px;
      height: 150px;
      margin-left: -75px;
    }
    .timeline > li .timeline-image h4,
    .timeline > li .timeline-image .h4 {
      font-size: 18px;
      line-height: 26px;
      margin-top: 30px;
    }
    .timeline > li.timeline-inverted > .timeline-panel {
      padding: 0 20px 20px;
    }
  }
  
  @media (min-width: 1200px) {
    .timeline > li {
      min-height: 170px;
    }
    .timeline > li .timeline-panel {
      padding: 0 20px 20px 100px;
    }
    .timeline > li .timeline-image {
      width: 170px;
      height: 170px;
      margin-left: -85px;
    }
    .timeline > li .timeline-image h4,
    .timeline > li .timeline-image .h4 {
      margin-top: 40px;
    }
    .timeline > li.timeline-inverted > .timeline-panel {
      padding: 0 100px 20px 20px;
    }
  }
  </style>
  